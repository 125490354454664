import React, {
    useCallback,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'
import APP_URLS from '@src/constants/AppUrls'
import consoleService from '@src/services/consoleService'
import openSupport from '@src/utils/jiraHelpDesk'
import ScanQRError from './components/ScanQRError'

function ScanQRErrorPage(): JSX.Element {
    const navigate = useNavigate()
    const handleBackClick = useCallback(() => {
        navigate(APP_URLS.scanQR)
    }, [navigate])

    const handleEnterManuallyClick = useCallback(() => {
        consoleService.log('handleEnterManuallyClick click')
    }, [])

    const jiraHelpDeskCallback = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const handleSupportClick = useCallback(() => {
        jiraHelpDeskCallback()
    }, [jiraHelpDeskCallback])

    return (
        <ScanQRError
            onBackClick={handleBackClick}
            onEnterManuallyClick={handleEnterManuallyClick}
            onSupportClick={handleSupportClick}
        />
    )
}

export default ScanQRErrorPage
