import React, {
    useCallback,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'
import useSetup from '@src/hooks/useSetup/useSetup'
import APP_URLS from '@src/constants/AppUrls'
import queryClient from '@src/services/queryClient'
import Summary from './components/Summary'

function SummaryPage(): JSX.Element {
    const [
        state,
        setState,
    ] = useSetup()
    const navigate = useNavigate()

    const handleSubmit = useCallback(() => {
        setState(() => {
            return {}
        })
        queryClient.resetQueries()
        navigate(APP_URLS.scanQR)
    }, [
        navigate,
        setState,
    ])

    return (
        <Summary
            gateway={`${state.gatewayMac}`}
            airport={`${state.selectedLocation?.iataCode} ${state.selectedLocation?.city}`}
            area={`${state.selectedArea?.areaName}`}
            onSubmit={handleSubmit}
        />
    )
}

export default SummaryPage
