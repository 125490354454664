import {
    UseMutationResult, useMutation,
} from 'react-query'
import axios from 'axios'
import dataURIToBlob from '@src/utils/dataUriToBlob'
import API_URLS from '@src/constants/ApiUrls'
import useGetAxiosConfig from './useGetAxiosConfig'

export interface IAddAttachmentRequest {
    file: string
    description: string
    fileName: string
}
export interface IAddAttachmentResponse {
    id: number
}
const useAddAttachment = (): UseMutationResult<
IAddAttachmentResponse, unknown, IAddAttachmentRequest> => {
    const config = useGetAxiosConfig()

    return useMutation(
        (props) => {
            const file = dataURIToBlob(props.file)
            const formData = new FormData()

            formData.append('file', file)
            formData.append('description', props.description)
            formData.append('fileName', props.fileName)

            return axios
                .post(API_URLS.getAttachmentUrl(), formData, {
                    ...config,
                    headers: {
                        ...config.headers,
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((data) => {
                    return data.data
                })
        },
        {
            mutationKey: 'useAddAttachment',
        },
    )
}

export default useAddAttachment
