import {
    useEffect,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'

import APP_URLS from '@src/constants/AppUrls'
import {
    IGatewayByIdResponse,
} from '@src/api/hooks/useGetGatewayById'
import {
    ISetupState,
} from '@src/hooks/useSetup/useSetup'

const useGatewayScanned = ({
    gatewayByIdData,
    gateway,
    gatewayByIdError,
    setState,
}:{
    gatewayByIdData?: IGatewayByIdResponse,
    gateway?: string,
    gatewayByIdError?: unknown,
    setState: (callback: (prev: ISetupState) => ISetupState, complete?: () => void) => void
}) => {
    const navigate = useNavigate()

    useEffect(() => {
        if (gatewayByIdData) {
            setState(
                (prev: ISetupState) => {
                    return {
                        ...prev,
                        gatewayMac: gateway,
                        gatewayInfo: gatewayByIdData,
                    }
                },
                () => {
                    return navigate(APP_URLS.gatewayDetected)
                },
            )
        } else if (gatewayByIdError) {
            setState(
                (prev: ISetupState) => {
                    return {
                        ...prev,
                        gatewayMac: undefined,
                    }
                },
                () => {
                    return navigate(APP_URLS.scanQRError)
                },
            )
        }
    }, [
        gatewayByIdData,
        gatewayByIdError,
        navigate,
        setState,
        gateway,
    ])
}

export default useGatewayScanned
