import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        select::-ms-expand {
            display: none;
        }
        select {
            width: 100%;
            display: inline-block;
            box-sizing: border-box;
            border-radius: 6px;
            caret-color: transparent;
            padding: 0.5em 2em 0.5em 0.5em;
            border: 1px solid #000;
            font: inherit;
            line-height: inherit;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            appearance: none;
            background-repeat: no-repeat;
            background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
                linear-gradient(135deg, currentColor 50%, transparent 50%);
            background-position:
                right 15px top 1em,
                right 10px top 1em;
            background-size:
                5px 5px,
                5px 5px;
            background-color: white;
            outline: none;
        }
        select:disabled {
          border-color: #D7D7D7;
        }
        option {
            width: 100%;
            padding: 8px;
        }
    `,

    label: css`
        padding-bottom: 7px;
    `,
}

export default classes
