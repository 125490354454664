import React, {
    useCallback,
} from 'react'

import useSetup from '@src/hooks/useSetup/useSetup'
import Loading from '@src/shared-components/Loading'
import useGetGatewayById from '@src/api/hooks/useGetGatewayById'
import consoleService from '@src/services/consoleService'
import openSupport from '@src/utils/jiraHelpDesk'
import useGatewayScanned from '@src/hooks/useGatewayScanned'

import parseGatewayQRCode from './parseGatewayQRCode'
import ScanQR from './components/ScanQR'

function ScanQRPage(): JSX.Element {
    const [
        state,
        setState,
    ] = useSetup()

    const handleOnSupportClick = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const {
        data: gatewayByIdData,
        isLoading: gatewayByLoading,
        error: gatewayByIdError,
    } = useGetGatewayById(state.gatewayMac || '', {
        enabled: !!state.gatewayMac,
        cacheTime: 0,
    })

    const handleScanSuccess = useCallback(
        (value: string) => {
            const parsedCode = parseGatewayQRCode(value)

            setState((prev) => {
                return {
                    ...prev,
                    gatewayMac: parsedCode || value,
                    gatewayInfo: undefined,
                    selectedLocation: undefined,
                }
            })
        },
        [setState],
    )

    useGatewayScanned({
        gatewayByIdData,
        gateway: state.gatewayMac,
        gatewayByIdError,
        setState,
    })

    return (
        <Loading isLoading={gatewayByLoading}>
            <ScanQR
                onSupportClick={handleOnSupportClick}
                onScanSuccess={handleScanSuccess}
            />
        </Loading>
    )
}

export default ScanQRPage
