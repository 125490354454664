import {
    UseQueryResult, useQuery,
} from 'react-query'
import axios from 'axios'

import API_URLS from '@src/constants/ApiUrls'
import useGetAxiosConfig from './useGetAxiosConfig'

export interface IGetLocationRequest {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    includeFilters?: any
    rows?: number
    start?: number
    searchAll?: string
    sortField?: string
    sortType?: string
    archived?: boolean,
    enabled?: boolean,
}
export interface ILocationModel {
    id: number
    locationName: string
    countryName: string
    iataCode: string
    city: string
}

const useGetLocations = ({
    enabled = true,
    ...props
}: IGetLocationRequest):
    UseQueryResult<ILocationModel[], unknown> => {
    const config = useGetAxiosConfig()

    return useQuery({
        queryKey: [
            'useGetLocations',
            props,
        ],
        queryFn: ({
            queryKey: [
                , body,
            ],
        }) => {
            return axios
                .post(
                    API_URLS.filterLocationUrl(),
                    body,
                    config,
                )
                .then((data) => {
                    return data.data.items
                })
        },
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled,
        keepPreviousData: true,
    })
}

export default useGetLocations
