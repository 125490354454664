import {
    useMemo,
} from 'react'
import {
    useQuery, UseQueryResult,
} from 'react-query'
import useGetAxiosConfig from '@src/api/hooks/useGetAxiosConfig'
import axios from 'axios'
import API_URLS from '@src/constants/ApiUrls'

export enum AUTH_ROLES {
    SGA_USER_ADVANCED= 'SGA_USER_ADVANCED',
    SCE_ADMIN = 'SCE_ADMIN',
}

type RolesModel = {
    name: string
}

const hasPermission = (permission: AUTH_ROLES, userRoles: RolesModel[]) => {
    return userRoles.some((role: {name: string}) => {
        return role.name === permission
    })
}

const useGetUserRoles = ():
    UseQueryResult<RolesModel[], unknown> => {
    const config = useGetAxiosConfig()

    return useQuery({
        queryKey: ['getUserRoles'],
        queryFn: () => {
            return axios
                .get(API_URLS.getRoles(), config)
                .then((data) => {
                    return data.data
                })
        },
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
    })
}

const useHasPermission = (perm: AUTH_ROLES) => {
    const {
        data,
        isFetched,
    } = useGetUserRoles()

    return useMemo(() => {
        return isFetched ? hasPermission(perm, data as RolesModel[]) : false
    }, [
        isFetched,
        data,
        perm,
    ])
}

export default useHasPermission
