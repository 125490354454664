import {
    useMutation,
} from 'react-query'
import axios, {
    AxiosRequestConfig,
} from 'axios'

import API_URLS from '@src/constants/ApiUrls'
import useGetAxiosConfig from '@src/api/hooks/useGetAxiosConfig'
import dataURIToBlob from '@src/utils/dataUriToBlob'
import {
    PhotoType,
} from '@src/context/PhotosContext/PhotosContext'

type Params = {
    file: string,
    fileName: string,
    description: string,
    config: AxiosRequestConfig,
}

export const createAttachment = ({
    file: fileDataUrl,
    fileName,
    config,
}: Params) => {
    const file = dataURIToBlob(fileDataUrl)
    const formData = new FormData()

    formData.append('file', file)
    formData.append('fileName', fileName)

    return axios
        .post(API_URLS.getAttachmentUrl(), formData, {
            ...config,
            headers: {
                ...config.headers,
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((data) => {
            return data.data
        })
}

const useSaveAttachments = (gatewayid: string, onSuccess: (result:PhotoType[]) => void) => {
    const config = useGetAxiosConfig()

    return useMutation((items: PhotoType[]) => {
        const promises = items.map((item, number) => {
            return createAttachment({
                description: 'my description',
                file: item.base64,
                fileName: `SkyGate-gatewayId-${gatewayid}-photo-N${number + 1}`,
                config,
            })
        })

        return Promise.all(promises).then((values) => {
            return values.map(({
                id,
            }, index) => {
                return {
                    ...items[index],
                    id,
                }
            })
        })
    }, {
        retry: false,

        onSuccess,
    })
}

export default useSaveAttachments
