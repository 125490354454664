import {
    UseMutationResult, useMutation,
} from 'react-query'
import axios from 'axios'
import API_URLS from '@src/constants/ApiUrls'
import useGetAxiosConfig from './useGetAxiosConfig'

const useCheckAreaAvailability = (onSuccess: ()=>void): UseMutationResult<
    unknown,
    unknown,
    { areaId: number }
> => {
    const config = useGetAxiosConfig()

    return useMutation(
        ({
            areaId,
        }) => {
            return axios
                .post(
                    API_URLS.checkAreaAvailability(areaId),
                    {},
                    config,
                )
        },
        {
            mutationKey: 'useCheckAreaAvailability',
            onSuccess,
        },
    )
}

export default useCheckAreaAvailability
