import {
    UseMutationResult, useMutation,
} from 'react-query'
import axios from 'axios'
import API_URLS from '@src/constants/ApiUrls'
import useGetAxiosConfig from './useGetAxiosConfig'

const useAcceptTerms = (): UseMutationResult => {
    const config = useGetAxiosConfig()

    return useMutation(
        () => {
            return axios.patch(API_URLS.acceptTermsUrl(), undefined, config)
        },
        {
            mutationKey: 'useAcceptTerms',
        },
    )
}

export default useAcceptTerms
