import {
    UseQueryResult, useQuery,
} from 'react-query'
import axios from 'axios'
import API_URLS from '@src/constants/ApiUrls'
import queryClient from '@src/services/queryClient'
import useGetAxiosConfig from './useGetAxiosConfig'

export interface IHasAcceptedTermsResponse {
    hasSignedTcAndGuide: boolean
}

const NAME = 'hasSignedTcAndGuide'

const useHasAcceptedTerms = ():
UseQueryResult<IHasAcceptedTermsResponse, unknown> => {
    const config = useGetAxiosConfig()

    return useQuery<IHasAcceptedTermsResponse, unknown>(
        NAME,
        () => {
            return axios.get(API_URLS.hasAcceptedTermsUrl(), config).then((data) => {
                return data.data
            })
        },
        {
            staleTime: Number.MAX_VALUE,
            retry: false,
        },
    )
}

export const updateHasAcceptedTermsCache = (newData: IHasAcceptedTermsResponse) => {
    queryClient.setQueriesData(NAME, newData)
}
export default useHasAcceptedTerms
