import React, {
    useState,
} from 'react'
import Button from '@src/shared-components/Button'
import Layout from '@src/Layout'
import Header from '@src/Layout/components/Header'
import MainPopup from '@src/MainPopup'
import ErrorSvg from '@src/assets/triangle_error.svg'
import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import classes from './ScanQRError.style'

type Props = {
    onBackClick: () => void
    onEnterManuallyClick: () => void
    onSupportClick: () => void
}

function ScanQRError({
    onBackClick, onEnterManuallyClick, onSupportClick,
}: Props): JSX.Element {
    const {
        t,
    } = useT()
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)

    return (
        <Layout
            header={(
                <Header
                    title={t('ERROR')}
                    onMenuClick={() => {
                        return showMainPopup((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <>
                    <Button
                        variant="primary"
                        onClick={onEnterManuallyClick}
                        disabled
                    >
                        {t('ENTER_MANUALLY')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={onBackClick}
                    >
                        {t('BACK')}
                    </Button>
                    <Button
                        variant="primary-outlined"
                        onClick={onSupportClick}
                    >
                        {t('SUPPORT')}
                    </Button>
                </>
            )}
        >
            <MainPopup
                isOpen={isPopupOpened}
                onClose={() => {
                    return showMainPopup(false)
                }}
            />

            <div css={classes.root}>
                <center>
                    <img
                        alt="Error"
                        src={ErrorSvg}
                        css={classes.logo}
                    />
                </center>
                <Typography
                    css={classes.header}
                    variant="headline5"
                >
                    {t('SOMETHING_WENT_WRONG')}
                </Typography>
                <Typography
                    css={classes.subHeader}
                    variant="body2"
                >
                    {t('SCAN_QR_ERROR_BODY')}
                </Typography>
            </div>
        </Layout>
    )
}

export default ScanQRError
