const APP_URLS = {
    accessDenied: '/access-denied',
    terms: '/terms',
    confirm: '/confirm',
    scanQR: '/scan-qr',
    scanQRError: '/scan-qr-error',
    gatewayDetectedWithGateway: '/gateway-detected/:gateway',
    gatewayDetected: '/gateway-detected',
    takePhoto: '/take-photo',
    takePhotoOverviewEmpty: '/take-photo-overview-empty',
    takePhotoOverview: '/take-photo-overview',
    installGateway: '/install-gateway',
    photoQuality: '/photo-quality',
    summaryPage: '/summary',
    error: '/error',
    locationConfirm: '/location-confirm',
    locationConfirmManual: '/location-confirm-manual',
}

export default APP_URLS
